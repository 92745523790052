import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const swiper = new Swiper(".swiper", {
  slidesPerView: 1,
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
  },
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  autoHeight: true,
});
const swiperPortfolio = new Swiper(".portfolio-swiper", {
  slidesPerView: 1.3,
  spaceBetween: 11,
  loop:true,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
    992:{
      spaceBetween: 40,
      slidesPerView: 3,
    }

  },
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  grabCursor:true,
  autoHeight: false,
  on: {
    slideChange: function () {
      const activeSlide = this.slides[this.activeIndex];
      const video = activeSlide.querySelector('video');

      if (video) {
        video.play();
      }
    },
    slideChangeTransitionEnd: function () {
      const previousSlide = this.slides[this.previousIndex];
      const previousVideo = previousSlide.querySelector('video');

      if (previousVideo) {
        previousVideo.pause();
      }
    }
  }

});
const swiperCourses = new Swiper(".courses-swiper", {
  slidesPerView: 1,
  centeredSlides:true,
  spaceBetween: 20,

  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  grabCursor:true,
  autoHeight: false,

});

const swiperGallery = new Swiper(".gallerySwiper", {
  slidesPerView: 1,
  centeredSlides:true,
  spaceBetween: 20,
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  autoHeight: false,
});
const swiperAll = new Swiper(".all-courses-swiper", {
  slidesPerView: 1,
  centeredSlides:true,
  spaceBetween: 20,
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  autoHeight: true,
});



