import { showBackdrop, hideBackdrop } from "./utils";
import refs from "./refs";

const { menuBackdrop,menuNavLinks, menuButton, appBackdrop, appButtons, hideButton,heroButton } =
    refs;

const handleAppButtonClick = () => {
    if (!$(menuBackdrop).hasClass("is-hidden")) {
        hideBackdrop(menuBackdrop);
    }

    showBackdrop(appBackdrop);
};

appButtons.on("click", handleAppButtonClick);
hideButton.on("click", () => hideBackdrop(appBackdrop));

menuNavLinks.on("click",() => hideBackdrop(menuBackdrop));

menuButton.on("click", function () {
    if ($(menuBackdrop).hasClass("is-hidden")) {
        showBackdrop(menuBackdrop, true);
    } else {
        hideBackdrop(menuBackdrop);
    }
});



const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {
            $(menuButton).toggleClass("is-active");
        }
    }
});

observer.observe(menuBackdrop[0], { attributes: true });
